import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Header() {

  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <FontAwesomeIcon
              icon={["fab", "magento"]}
              className="ms-1"
              size="lg"
            />
            <span className="ms-2 h5">MUKE GROUP</span>
          </Link>

          <div className={"navbar-collapse offcanvas-collapse "}>
            <ul className="navbar-nav me-auto mb-lg-0"/>
            <button type="button" className="btn btn-outline-dark me-3 d-none d-lg-inline">
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
              <span className="ms-3 badge rounded-pill bg-dark">0</span>
            </button>
          </div>

          <div className="d-inline-block d-lg-none">
            <button type="button" className="btn btn-outline-dark">
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
              <span className="ms-3 badge rounded-pill bg-dark">0</span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
