export const products = [
    {
        "id": 271937,
        "title": "smartwatch ijyamo simcard,memorycard nibindi..",
        "price": 19.23076923076923,
        "description": "iyi saha ibamo calculator, yakira message, call , ikina video hamwe nindirimbo, ifata ifoto, ikora ibintu byinshi cyane kuburyo kuyitunga biba bimeze nko kugira telephone kukuboko kwawe\u2026wayikoresha mukazi, utwaye, muri classe, cyagwa se warabuze telephone yawe kandi ushaka kutava kumurongo kuko ijyamo simcard",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-41-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/39-dz-micro-sim-card-watch-android-ios-itech-yes-original-imaghtkmtqsgjkfr-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/39-dz-micro-sim-card-watch-android-ios-itech-yes-original-imaghw8rfb83sscg-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/61c2ed72-31f8-4acb-9653-5e860a79e2d7_1.f07780a4b640f7df6a584cd460ef10ac-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/DZ09-Smartwatch-Men_Women-Main_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/DZ09-Smartwatch-Men_Women-Pic-2-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/Dz09-Smartwatch-SIM-Card-Q18-Bracelet-Camera-Bt-Call-Wristwatch-Ios-Android-Waterproof-Smart-Watch-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/e960cc70-4d2b-459f-8b59-fac11b9f7522.70f3c612d8435a1ddd0527506ca22325-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/jokin-black-wireless-bluetooth-smart-watch-product-images-orv8aqyrbiv-p594392596-3-202210102301-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-41-600x600.png"
    },
    {
        "id": 815951,
        "title": "250ml mini humidifier",
        "price": 6.923076923076923,
        "description": "Aka kantuwongeramo amazi , ukanongeramo ibintu bihumuza nka parufe cyangwa essential oil, maze kakagufasha kuba wahumuza ahantu hose uri bitewe numwotsi gasohora iyo kari kwaka.Aka gashibora kugufasha unafite ibibazo byubuhemekeroIyo aka kantu gacanye gashobora kukurinda kunanirwa cyane cyane nkabantu bakorera kuri computer cyagwa bayimaraho igihe kinini",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Untitled-design-2-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/842391-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/Mini-air-humidifier-B7-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/946612091_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/752964713_max-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Untitled-design-2-600x600.png"
    },
    {
        "id": 688911,
        "title": "360 rotating Magic hanger kagufasha kongera gusavinga umwanya",
        "price": 4.615384615384615,
        "description": "Aka kagufasha kongera umwanya wawe mukabati,garderobe,nibindi\u2026",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-28-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/5f7b456205b27f4fccc49fb5252bf02c.jpg_750x750.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/8cdc1250-32e9-4290-8bdd-f3771ee90b21.f03ff831c0faaf84d116f080938ba169-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/71MClnz7pVL._AC_UF8941000_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/1694744145c7d9ed5e845f3d8aa9a6a1cb5da81f0c_thumbnail_720x-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/bc77c8cf15646784c1cce25ca083cefd-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/11/multifunction_hanger_storage_r_1681045369_c6e6f4d5_progressive-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-28-600x600.png"
    },
    {
        "id": 206366,
        "title": "Portable mini blender",
        "price": 14.615384615384615,
        "description": "aka naka blander gato shobora kwitwaza nahantu hose, kagufasha gukora jucie,no gusya ibindi bintu, wagakenera murugo nahandi\u2026.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/12/Tuza-ari-24-52-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/12/images-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/12/400ml-4-6-Blades-Mini-Portable-Electric-Fruit-Juicer-USB-Rechargeable-Smoothie-Maker-Blender-Machine-Sports-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/12/8acc00c1-9d91-4924-a6a6-e22601052031.efbb1da9b118cbaa0da945027179e5dc-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/12/Tuza-ari-24-52-600x600.png"
    },
    {
        "id": 530967,
        "title": "Gatubura amashusho yo muri telephone",
        "price": 6.923076923076923,
        "description": "iyo ushyize telephone inyuma yako, gahita gafata ibintu biri muri telephone kakabigira binini,maze wowe ukakareberaho ubona amashusho ari manini, bimeze nkaga televison gato.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-14-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/k-s-oss-1697029173146kTiG3PQdAi-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/mobile-security-stands-500x500-1-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/61s1Kh9uIpL._SX679_-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-14-600x600.png"
    },
    {
        "id": 2522,
        "title": "LCD Electronic board yo kwandikaho 8.5INCH",
        "price": 10.0,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/03/before-5-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247686-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247558-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247342-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933193443-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/pack-lcd-writing-tablet-8-5-inch-electronic-drawing-pads-for-kids-portable-reusable-erasable-ewrit-500x500-1-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/images-1-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/f5b9c499e894c42269cc679b09859f89.jpg_720x720q80-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/0d2b389835ba917ad096c97b4648a4bc-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/03/before-5-600x600.png"
    },
    {
        "id": 898102,
        "title": "Portable Wireless Lavalier Microphone",
        "price": 12.307692307692308,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-24-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/1-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/71tdXVU15nL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/71YaCV-yjqL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/234c60b4-3964-4fe7-87e7-0acd83e16f97-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/06/833891496_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/rBNaEmKXTaGAYhAQAAEN-uJHHpU860-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/64d63369-c336-49e8-8cde-d394d642ecfb-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/1074607889-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/61WHAYxPVjL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/3-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-24-600x600.png"
    },
    {
        "id": 852656,
        "title": "LCD Electronic board yo kwandikaho 8.5INCH",
        "price": 10.0,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/03/before-5-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247686-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247558-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933247342-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-1621933193443-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/pack-lcd-writing-tablet-8-5-inch-electronic-drawing-pads-for-kids-portable-reusable-erasable-ewrit-500x500-1-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/images-1-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/f5b9c499e894c42269cc679b09859f89.jpg_720x720q80-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/0d2b389835ba917ad096c97b4648a4bc-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/03/before-5-600x600.png"
    },
    {
        "id": 414720,
        "title": "Portable Wireless Lavalier Microphone",
        "price": 12.307692307692308,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-24-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/1-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/71tdXVU15nL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/71YaCV-yjqL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/234c60b4-3964-4fe7-87e7-0acd83e16f97-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/06/833891496_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/rBNaEmKXTaGAYhAQAAEN-uJHHpU860-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/64d63369-c336-49e8-8cde-d394d642ecfb-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/1074607889-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/61WHAYxPVjL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/06/3-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-24-600x600.png"
    },
    {
        "id": 888873,
        "title": "utuntu 24 tugufasha gushushanya ingohe zawe",
        "price": 3.8461538461538463,
        "description": "Niba ukigorwa no gushushanya ingohe zawe, cyagwa niba bigufata umwanya munini.. utu tuntu nigisubizo kuri wowe kuko hamwe natwo bigufata umwanya utarenze iminota 3 gusa kuba urangije kuzikora nezakandi ikirenze nuko tuguha ama shape atandukanye kuburyo ushobora guhitamo iyo ushaka bitewe na style ushaka ako kanya.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/3-pcs-different-style-reusable-eyebrow-card-stencil-eye-brow-original-imagyzfqrjtwpa8e-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/100__94472-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/images-1-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/09/Reusable-Eyebrow-Template-and-Eyebrow-Assist-Artifact-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/stencil1_480x480-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-600x600.png"
    },
    {
        "id": 744866,
        "title": "Beard roller na mavuta bikuza umusatsi nubwanwa",
        "price": 8.461538461538462,
        "description": "Aya namavuta azana na roller yabagabo nabagore agufasha gukuza ubwanwa vuba cyagwa kumeza umusatsi aho ugenda ushiraho cyagwa wacitse, NTAGARUKA NIMWE KAGIRA KUKO GAKORA MUBURYO BURI NATURALLE.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-15-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/8bbebf366b10306e8d25e4e96a84da90.jpg_750x750.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/100-1rth-roller-for-hair-growth-and-beard-growth-0-5mm-540-micro-original-imagscwgb2kc7evg-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/62955501c7e2401c23e9eb3fdfb821ad-600x600-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/700174036_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/943793837_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/images-2-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/09/Men-Beard-Growth-Roller-Set-Beard-Growth-Kit-Men-s-Beard-Growth-Essence-Nourishing-Enhancer-Beard-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/o3tua_512-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-15-600x600.png"
    },
    {
        "id": 1254,
        "title": "Beard roller na mavuta bikuza umusatsi nubwanwa",
        "price": 8.461538461538462,
        "description": "Aya namavuta azana na roller yabagabo nabagore agufasha gukuza ubwanwa vuba cyagwa kumeza umusatsi aho ugenda ushiraho cyagwa wacitse, NTAGARUKA NIMWE KAGIRA KUKO GAKORA MUBURYO BURI NATURALLE.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-15-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/8bbebf366b10306e8d25e4e96a84da90.jpg_750x750.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/100-1rth-roller-for-hair-growth-and-beard-growth-0-5mm-540-micro-original-imagscwgb2kc7evg-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/62955501c7e2401c23e9eb3fdfb821ad-600x600-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/700174036_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/943793837_max-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/images-2-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/09/Men-Beard-Growth-Roller-Set-Beard-Growth-Kit-Men-s-Beard-Growth-Essence-Nourishing-Enhancer-Beard-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/o3tua_512-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-15-600x600.png"
    },
    {
        "id": 24991,
        "title": "resistance bands zigufasha gukora sport murugo",
        "price": 5.384615384615385,
        "description": "iyi ni qualite ya 1*Gafite resistance ihagije kuburyo gashobora gukururuka bihagije*Uko wagakwedura kose ntago gata resistance*Gakoreshwa nabagore nabagaboInformation* Izina: new 8 word Rally*Ibyo gakozwemo: foam, latex*Ingano: 40 * 15cm",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-9-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/1-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/11/1545a3a0-d9d3-4b7c-9a8b-d00c26604b19.88ef9641e95e218d39b2eb1a9475020f-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/16946734649fbad665a657d73be5b5a7a24acb1ffc_thumbnail_720x-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/cfe773d5-52f2-4c2c-9c4b-1bd69319d1e8.ec5ec6e7203a163da5232d2206a811b5-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/f5e36feb-963a-47c8-a4bb-40d5880a3d50.1d44aa2d87ec961ecd6e0172b7d8c516-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/Workout-Chest-Expander-Rope-8-Shaped-Word-Resistance-Band-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-9-600x600.png"
    },
    {
        "id": 872484,
        "title": "Abs-aka machine gatwika ibinure byo munda",
        "price": 10.0,
        "description": "Aka kamachine gafasha gutwika ibinure byo munda yawe kakagufasha kuzana abdominal . Niba ushaka kuzana ABS muburyo bworoshye kandi vuba, aka kazagufasha cyane. Wowe icyo usabwa nukukambara burimunsi , impinduka uzibona vuba",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-10-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/8d0e23c3-1ff0-4416-8885-4efdbb65c40d.4ca9da3d1b96751d75130d719da52fd8-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/16891539680b6dabacdeaa7b0dfcbfbea877b04dd8_square-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/ab-toner-ems-wireless-abs-stimulator-abdominal-muscle-easystore66-1810-03-EasyStore66_1_3bfb5e11-c741-461b-b1e9-2fcc6307df9c_800x-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/11/c23fd5a6d28537f5c9c15e7fd4740491-100x100.jpeg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-10-600x600.png"
    },
    {
        "id": 734855,
        "title": "Gloves za sport (Gym,Training,Bicycle, Motorcyclist)",
        "price": 8.461538461538462,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/12/Untitled-design-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/12/S5f80c83979a645ab82d9a0b01aec72daE-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/12/S8d9007d123954a028195698f40b3c2dcb-1-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/12/8e621433-3080-4f55-b5e0-31e3903ff7db_800x800-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/12/Untitled-design-600x600.png"
    },
    {
        "id": 129288,
        "title": "Jawline Muscle Facial Toner Cheekbones Trainer",
        "price": 3.8461538461538463,
        "description": "aka kagufasha kugabanya amatama nokuzana jawline cyangwa se urwasaya rumaze neza, gakoreshwa nibitsina byose.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/03/before-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/03/Sc348d97128fc409abeafb20c9e4e5610F.jpg_640x640Q90.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/S31c404c84c5c40d8b2228c2239893751u.jpg_640x640Q90.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/k-s-oss-1653965489749tQ33eWHZPw-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/images-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/03/1700040575522-cf8d727f8d3f43c39e29e64037716a96-goods-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/4-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/03/before-600x600.png"
    },
    {
        "id": 548187,
        "title": "shanete y\u2019inyenyeri nini idacuya",
        "price": 4.615384615384615,
        "description": "NB:ntago icuya",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/01/Tuza-ari-24-7-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/01/1700235409021-a013c853013c4f6ab85b9370c12f8a9a-goods-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/01/1700235409389-ebb07048ff194035b7daa74f444fab82-goods-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/01/1700235409818-ff0919df1115490ba91dda8542ab60a9-goods-100x100.jpeg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/01/Tuza-ari-24-7-600x600.png"
    },
    {
        "id": 70363,
        "title": "chain 2 zifatanye zabagabo",
        "price": 5.384615384615385,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/02/Tuza-ari-24-11-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/02/k-s-oss-1690256067649hwTWGPDyKF-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/02/k-s-oss-1690256067649y8wCDJ8Bd8-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/02/k-s-oss-1690256067649CJbxTNdjJR-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/02/Tuza-ari-24-11-600x600.png"
    },
    {
        "id": 575677,
        "title": "Black Exquisite Minimalist Square box chain",
        "price": 6.153846153846154,
        "description": "aka nagashanete keza kabakobwa , ushobora kukambara cyagwa ukagatanga nkagiftNB: NTAGO GACUYA",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/03/before-1-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/03/il_fullxfull.5810199393_4xan-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/Minimalist-Black-Exquisite-Square-Pendant-Necklace-Stainless-Steel-Choker-Chains-Fashion-Necklaces-For-Women-Jewelry-Party-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-55239383997387830-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-55239382669869209-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/03/before-1-600x600.png"
    },
    {
        "id": 185218,
        "title": "love necklace, ntago gacuya",
        "price": 4.615384615384615,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/04/Untitled-design-9-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/04/81c644516872564cf9612b6d584908a7-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/04/Hdb67427b40274c0e9f85292f99b090a0R.jpg_640x640Q90.jpg_-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/04/Hf0bb2528e7934ef98161982a53ccdd5bl-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/04/Untitled-design-9-600x600.png"
    },
    {
        "id": 769373,
        "title": "agaherena kakamadikano ko kuzuru",
        "price": 3.8461538461538463,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-5-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/7a4ae00adba85c4536c67a39bdfcb743eeb9e5c7_original-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2024/06/a57ebd4e-51c2-4430-a416-610bea8076c1.71df788345de65372f255506360758d3-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-5-600x600.png"
    },
    {
        "id": 442429,
        "title": "udukomo two kumaboko tuza ari 4",
        "price": 6.153846153846154,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-14-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-18-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-17-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-15-100x100.png"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/06/Untitled-design-14-600x600.png"
    },
    {
        "id": 659425,
        "title": "T55 smart watch +Tws earphone+imikoba 2 byose tubiguhera rimwe",
        "price": 25.384615384615383,
        "description": "nubwo iyi saha ya t55 arinziza ikorohereza ubuzima ikaba ugufasha gukoresha telephone yawe byoroshye , tunayiguhana na earpieces za Tws hamwe nimikoba ibiri yisaha kuburyo uba washobora guhinduranya",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/12/Tuza-ari-24-47-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/12/1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/12/4-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/12/99-1634328873-1634328892-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/12/T34TT1ia8A-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/12/T55-Pro-Max-Smartwatch-Key-Features-and-Price-in-Bangladesh-5-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/12/t55-pro-max-smart-watch-with-earbuds-double-strap-black-color-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/12/Tuza-ari-24-47-600x600.png"
    },
    {
        "id": 453965,
        "title": "isaha y\u2019uruhu izana nudukomo 4 natwo turuhu",
        "price": 13.846153846153847,
        "description": "No description available",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/03/before-3-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/03/k-s-oss-1704327799297MTNQrXfkcA-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-41629282526267110-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/03/upload-productImg-41629278560891035-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/03/before-3-600x600.png"
    },
    {
        "id": 496657,
        "title": "isaha ziza ari 2 za couple zidacuya",
        "price": 14.615384615384615,
        "description": "iyisaha ushobora kuyigurira girlfreind wawe, boyfreind wawe, mamawawe, sister wawe, bestfreind wawe cyagwa nabandi",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/05/Untitled-design-1-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/05/3710194648_1247976857-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/05/3711696624_1247976857-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/05/3710200367_1247976857-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/05/Untitled-design-1-600x600.png"
    },
    {
        "id": 436530,
        "title": "Electric mixer igufasha kuvanga ibintu bitandukanye",
        "price": 6.153846153846154,
        "description": "Milk,coffee,mocha,cappucino,drink,eggs etc.. mixer, igufasha kuvanga burikintu cyose gisukika kandi ikaba ifite ingufu kuburyo yavanga ni jage yuzuye, gakoreshwa namabuye 2.",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-9-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/7d50126b-e784-4a8b-ad5f-0d3e44e7ed06.98780c34e26e175efd779067c023d82e-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/516AQItIkZL._AC_UF350350_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/747fb434-8564-4410-bc4d-99e15971fd0f.b9b87df28db3d37de6aab5755e16b87a-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/73633566-4e60-4b9e-bf22-31607410f005.614c4102b53380abef78475a5455f2b5-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/1667549897904-03e6828988d940ee8c7392339a70769c-goods-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/09/upload-productImg-19081538279042587-100x100.jpeg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-9-600x600.png"
    },
    {
        "id": 205862,
        "title": "mini ringlight igufasha gukesha amafoto yawe na mavideo",
        "price": 6.923076923076923,
        "description": "mini ring light igufasha gufata amavideo namafoto akeye kandi meza mugihe icyaricyo cyose ,",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Untitled-design-5-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/Cheap-Price-Rechargeable-Battery-Mini-Selfie-Ring-Light-Portable-Phone-Table-PC-LED-Light-for-Photography-Live-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/cee20bc6-f5be-47f7-935b-f13bc1cbc53b_nube-58bfed343d98b8e60215245346992747-640-0-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/187912_1621630810-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/51rv16xFDxL._AC_UF10001000_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/09/31l1nb1g-rS._AC_UF10001000_QL80_-100x100.jpg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Untitled-design-5-600x600.png"
    },
    {
        "id": 736257,
        "title": "Aga fan gato kadasaba battery",
        "price": 5.384615384615385,
        "description": "Byinshi kuriyi fan1. Kugira gakore nta mabuye kanjyamo cyagwa ikindi, ukanda kuri ako kantu karihasi kumweru kugira kizengurutse2. nikeza kugantanga nkimpano3. Gafite umuyaga uhagije4. Ingano: 72*44*62mm",
        "images": [],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-13-600x600.png"
    },
    {
        "id": 152869,
        "title": "Electric hair comb irambura umusatsi",
        "price": 19.23076923076923,
        "description": "icyi nigisokozo ucomeka maze cyigashyuha cyikakorohereza kurambura umusatsi no kuwusokoza byorosshye bitakubabaza, kigira na ga screen gato inyuma kakwereka aho ubushyuhe bugeze",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2024/07/Untitled-design-25-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2024/07/iix-professional-ceramic-hair-straightener-brush-iron-comb-90-original-imagbqmghmar3dga-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/07/Electric-Heating-Comb-Professional-Hair-Straightening-Brush-Adjustable-Temperature-Hair-Straightener-Women-s-Hair-Hot-Comb-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2024/07/81xNftgOXnL._AC_SX679_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/07/k-s-oss-1677478704621WwrpkzjxKD-1-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/07/k-s-oss-1677478704621XXXyXiGHbf-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/07/k-s-oss-1677478704621JJzGA526G7-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/07/k-s-oss-1677478704621WwrpkzjxKD-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2024/07/k-s-oss-1677478704621XXXyXiGHbf-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2024/07/Untitled-design-25-600x600.png"
    },
    {
        "id": 542365,
        "title": "computer glasses zikurinda urumuri",
        "price": 7.6923076923076925,
        "description": "computer iyo uyimaraho igihe kinini, igutera ibazo bitandukanye nko kuribwa namaso, kumva urambiwe, kuriba umutwe nibindi.. ariyo mpamvu izi luinette zibaho kugira zibikurinde bitewe nubuhanga zikoranye bukumira urumuri ritwa (blue light).",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-5-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/09/701dd70f652500b35966fd372132d24e-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/b2db1384-66bf-43db-8e48-f609abe06e69_800x800-100x100.webp",
            "https://nihemart.rw/wp-content/uploads/2023/09/product-image-1320713435_530x@2x-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/09/Tuza-ari-24-5-600x600.png"
    },
    {
        "id": 592839,
        "title": "Mini projector ikorana na phone soze ,usb nibindi",
        "price": 49.23076923076923,
        "description": "aka ugafite ntanimpamvu waba ufite yo gutunga tv kuko kaba kameze nka tv ya 75\u2033 bitewe nuburyo wegereye igikuta,nigato kandi kakoroshye kukagendana",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-2-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/41xzYmKebmL._SR600315_PIWhiteStripBottomLeft035_SCLZZZZZZZ_FMpng_BG255255255-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/51EeSoEzOUL._AC_UF10001000_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/61bFnHDnwGL._AC_UF10001000_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/71ffjdUy9lL-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/618Tlp1VlIL._AC_UF10001000_QL80_-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/k-s-oss-1657549182016BGmHYnzE8S-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/s-l400-100x100.jpg",
            "https://nihemart.rw/wp-content/uploads/2023/11/YT200-Mini-Projector-Portable-LCD-Video-Movie-Multimedia-Home-Theater-Cinema-Player-LED-Beamer-Projection-Device-100x100.webp"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-2-600x600.png"
    },
    {
        "id": 480572,
        "title": "His queen, her queen necklaces zidacuya",
        "price": 10.76923076923077,
        "description": "Izi necklace zikozwe muri titaniam ninziza ntakibazo zaguteza.wagakoresha nkimpano, kuri valentine cyagwa nk\u2019ikimenyetso cyurukundo nubudahemuka kuri mugenzi wawe.Tuba dusa neza kubatwambayeTuza ari 2 kamwe kumuhungu nakandi kumukobwa",
        "images": [
            "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-16-100x100.png",
            "https://nihemart.rw/wp-content/uploads/2023/11/34a226c5e26bf48b64dfba0565842f033fe09441_original-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/11/upload-productImg-1513336262723-100x100.jpeg",
            "https://nihemart.rw/wp-content/uploads/2023/11/upload-productImg-1513336262800-100x100.jpeg"
        ],
        "thumbnail": "https://nihemart.rw/wp-content/uploads/2023/11/Tuza-ari-24-16-600x600.png"
    }
]