import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { products } from "../data/products";
import FeatureProduct from "../landing/FeatureProduct";
import ScrollToTopOnMount from "../template/ScrollToTopOnMount";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ProductDetail() {
  const { id } = useParams();
  // eslint-disable-next-line eqeqeq
  const product = products.find((product) => product.id == id)
  if (!product) {
    return null;
  }

  const generateWhatsAppLink = () => {
    const message = `Murakoze guhitamo, mutegerezeho igihe gito haraba haje umuntu ubafasha
Product Name: ${product.title}
Quantity: 1
Price: FR ${Math.ceil(product.price * 1300).toLocaleString()}
Url: ${window.location.href}`;

    return `https://api.whatsapp.com/send?phone=+250786676061&text=${encodeURIComponent(message)}`;
  };

  return (
    <div className="container mt-5 py-4 px-xl-5">
      <ScrollToTopOnMount />
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-12 mb-4">
              <img
                className="border rounded ratio ratio-1x1"
                alt=""
                src={product.thumbnail}
              />
            </div>
          </div>

          {product.images.length > 0 &&
            <div className="row mt-2">
              <div className="col-12">
                <div
                  className="d-flex flex-nowrap"
                  style={{ overflowX: "scroll" }}
                >
                  {product.images.map((image, i) => {
                    return (
                      <a key={i} href="!#">
                        <img
                          className="cover rounded mb-2 me-2"
                          width="70"
                          height="70"
                          alt=""
                          src={image}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>}
        </div>

        <div className="col-lg-5">
          <div className="d-flex flex-column h-100">
            <h2 className="mb-1">{product.title}</h2>
            <h4 className="text-muted mb-4">FR {Math.ceil(product.price * 1300).toLocaleString()}</h4>

            <div className="row g-3 mb-4">
              <div className="col">
                <button className="btn btn-outline-dark py-2 w-100">
                  Add to cart
                </button>
              </div>
              <div className="col">
                <a href={generateWhatsAppLink()} className="btn btn-success py-2 w-100">
                  Order On Whatsapp
                  <FontAwesomeIcon className="ms-2" icon={["fab", "whatsapp"]} size="1x" />
                </a>
              </div>
            </div>
            <h4 className="mb-0">Description</h4>
            <hr />
            <p className="lead flex-shrink-0">
              <small>
                {product.description}
              </small>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-4">
          <hr />
          <h4 className="text-muted my-4">Related products</h4>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3">
            {products.slice(0, 4).map((product, i) => {
              return <FeatureProduct product={product} key={i} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;