import { Link } from "react-router-dom";
/**
 * 
 * @param {*} product : {
    id: string;
    name: string;
    price: number;
    currency: string;
    category: string;
    brand: string;
    manufacturer: string;
    colors: string[];
    status: string;
    rating: number;
    description: string;
} 
 * @returns 
 */
function FeatureProduct({ product}) {
  return (
    <div className="col">
      <div className="card shadow-sm">
        <img
          className="card-img-top bg-dark cover"
          height="240"
          alt=""
          src={product.thumbnail}
        />
        <div className="card-body">
          <h5 className="card-title text-center">{product.title}</h5>
          <p className="card-text text-center text-muted">FR {Math.ceil(product.price*1300).toLocaleString()}</p>
          <div className="d-grid gap-2">
            <Link to={`/products/${product.id}`} className="btn btn-outline-dark" replace>
              Detail
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;
